import { HttpRequest, HttpHandlerFn, HttpInterceptorFn } from '@angular/common/http';

import { injectAuthStore } from '@bto/auth/store/auth.store';

export const authenticationInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const token = injectAuthStore().user()?.key;
  if (!token || !req.url.includes('api')) {
    return next(req);
  }
  const modifiedReq = req.clone({
    headers: req.headers.set('Authorization', `Token ${token}`),
  });

  return next(modifiedReq);
};
