import { Routes } from '@angular/router';
import { provideTranslocoScope } from '@jsverse/transloco';

import { AppRouteNames } from '@bto/app.route-names';
import {
  hasActiveSubscriptionGuard,
  hasNoActiveSubscriptionGuard,
  isLoggedInGuard,
  isNotLoggedInGuard,
} from '@bto/auth/guards/auth.guards';
import { reloadWithCorsGuard, reloadWithNoCorsGuard } from '@bto/shared/guards/reload-with-cors.guard';
import { TranslocoScopesEnum } from '@bto/shared/transloco/transloco-scopes';

export const appRoutes: Routes = [
  {
    path: AppRouteNames.LANDING,
    loadChildren: () => import('./landing/landing.routes'),
    providers: [provideTranslocoScope(TranslocoScopesEnum.LANDING)],
  },
  {
    path: AppRouteNames.AUTH,
    loadChildren: () => import('./auth/auth.routes'),
    providers: [provideTranslocoScope(TranslocoScopesEnum.AUTH)],
    canActivate: [reloadWithNoCorsGuard, isNotLoggedInGuard],
  },
  {
    path: AppRouteNames.HOME,
    loadChildren: () => import('./home/home.routes'),
    providers: [provideTranslocoScope(TranslocoScopesEnum.HOME)],
    canActivate: [isLoggedInGuard],
  },
  {
    path: AppRouteNames.EDITOR,
    loadChildren: () => import('./editor/editor.routes'),
    providers: [provideTranslocoScope(TranslocoScopesEnum.EDITOR)],
    canActivate: [reloadWithCorsGuard, isLoggedInGuard, hasActiveSubscriptionGuard],
  },
  {
    path: AppRouteNames.BILLING,
    loadChildren: () => import('./billing/billing.routes'),
    providers: [provideTranslocoScope(TranslocoScopesEnum.BILLING)],
    canActivate: [reloadWithNoCorsGuard, isLoggedInGuard, hasNoActiveSubscriptionGuard],
  },
  {
    path: '**',
    redirectTo: AppRouteNames.LANDING,
  },
];
