import { EnvironmentInjector, inject, runInInjectionContext } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { UrlTree } from '@angular/router';
import { filter, map } from 'rxjs';

import { AppRouteNames } from '@bto/app.route-names';
import { AuthRouteNames } from '@bto/auth/auth.route-names';
import { injectAuthStore } from '@bto/auth/store/auth.store';
import { LoaderService } from '@bto/shared/services/loader.service';
import { createUrlTree } from '@bto/shared/utils/route.utils';

export const isLoggedInGuard = () =>
  !!injectAuthStore().user()?.key || createUrlTree(AppRouteNames.AUTH, AuthRouteNames.LOGIN);

export const isNotLoggedInGuard = () => !injectAuthStore().user()?.key || createUrlTree(AppRouteNames.HOME);

const hasActiveSubscriptionGuardFactory = (shouldHaveSubscription: boolean, redirectTo: AppRouteNames) => () => {
  const injector = inject(EnvironmentInjector);
  return toObservable(inject(LoaderService).isLoading('getAccount'))
    .pipe(filter(isLoading => !isLoading))
    .pipe(
      map(() =>
        runInInjectionContext<boolean | UrlTree>(
          injector,
          () => injectAuthStore().isSubscribed() === shouldHaveSubscription || createUrlTree(redirectTo)
        )
      )
    );
};

export const hasActiveSubscriptionGuard = hasActiveSubscriptionGuardFactory(true, AppRouteNames.BILLING);
export const hasNoActiveSubscriptionGuard = hasActiveSubscriptionGuardFactory(false, AppRouteNames.HOME);
