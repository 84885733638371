import { CanActivateFn } from '@angular/router';

const reloadWithCorsOrWithoutGuard =
  (enableCors: boolean): CanActivateFn =>
  (route, state) => {
    if ((typeof SharedArrayBuffer === 'undefined') === enableCors) {
      window.location.href = state.url;
      return false;
    }

    return true;
  };

export const reloadWithCorsGuard: CanActivateFn = reloadWithCorsOrWithoutGuard(true);

export const reloadWithNoCorsGuard: CanActivateFn = reloadWithCorsOrWithoutGuard(false);
