import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApplicationConfig, provideExperimentalZonelessChangeDetection } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';

import { appRoutes } from '@bto/app.routes';
import { authenticationInterceptor } from '@bto/auth/inteceptors/auth.interceptor';
import { translocoProviders } from '@bto/shared/transloco/transloco.config';
import { environment } from '@environment';
import { HlmDialogService } from '@lib/ui-dialog-helm/src';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(appRoutes),
    provideHttpClient(withInterceptors([authenticationInterceptor, ...environment.interceptors])),
    provideExperimentalZonelessChangeDetection(),
    provideAnimations(),
    ...translocoProviders,
    HlmDialogService,
    environment.providers,
  ],
};
