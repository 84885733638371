import { APP_INITIALIZER, ApplicationConfig, isDevMode } from '@angular/core';
import { provideTransloco } from '@jsverse/transloco';
import { provideTranslocoLocale } from '@jsverse/transloco-locale';
import { provideTranslocoMessageformat } from '@jsverse/transloco-messageformat';

import { LanguageService } from '@bto/shared/services/language.service';

import { TranslocoHttpLoader } from './transloco-loader';

export const availableLocales = {
  en: 'en-US',
  // pl: 'pl-PL',
  // fr: 'fr-FR',
};

export const availableLanguages = Object.keys(availableLocales) as Array<keyof typeof availableLocales>;

export const translocoProviders: ApplicationConfig['providers'] = [
  provideTransloco({
    config: {
      availableLangs: availableLanguages,
      reRenderOnLangChange: true,
      prodMode: !isDevMode(),
    },
    loader: TranslocoHttpLoader,
  }),
  provideTranslocoLocale({
    langToLocaleMapping: availableLocales,
  }),
  provideTranslocoMessageformat({ locales: availableLanguages }),
  {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: (languageService: LanguageService) => () => languageService.init(),
    deps: [LanguageService],
  },
];
