import { isPlatformServer } from '@angular/common';
import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { TranslocoService } from '@jsverse/transloco';

import { REQUEST } from '@bto/shared/injection-tokens/request.injection-token';
import { availableLanguages } from '@bto/shared/transloco/transloco.config';

type MetaTranslation = {
  description: string;
  keywords: string[];
  title: string;
};

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private readonly platformId = inject(PLATFORM_ID);
  private readonly request = inject(REQUEST, { optional: true });
  private readonly translocoService = inject(TranslocoService);
  private readonly meta = inject(Meta);
  private readonly title = inject(Title);

  init() {
    this.translocoService.setActiveLang(
      availableLanguages.find(lang => this.getLanguage().includes(lang)) || availableLanguages[0]
    );
    this.setupMetaAndTitleUpdate();
  }

  private setupMetaAndTitleUpdate() {
    this.translocoService.selectTranslateObject('meta').subscribe(({ description, title }: MetaTranslation) => {
      this.updateTag('description', description);
      this.updateTag('title', title);
      this.updateTag('twitter:title', title);
      this.updateTag('twitter:description', description);
      this.updateTag('og:url', 'https://beepthatout.com/', true);
      this.updateTag('og:title', title, true);
      this.updateTag('og:description', description, true);
      this.updateTag('og:image', '/assets/img/logo.webp', true);
      this.updateTag('og:locale', this.translocoService.getActiveLang(), true);
      this.title.setTitle(title);
    });
  }

  private updateTag(name: string, content: string, isProperty = false) {
    this.meta.updateTag(
      { itemprop: name, [isProperty ? 'property' : 'name']: name, content },
      `${isProperty ? 'property' : 'name'}=${name.replace(':', '\\:')}`
    );
  }

  private getLanguage() {
    if (isPlatformServer(this.platformId)) {
      return this.request?.headers['accept-language']?.substring(0, 2) || 'en';
    }
    return navigator.language;
  }
}
